import { Box, styled } from '@ghq-abi/design-system';

export const DetailSpan = styled('span', {
  fontSize: '$sm',
  fontWeight: '$bold',
  color: '#2e2c34',
});

export const TextSpan = styled('span', {
  fontSize: '$sm',
  fontWeight: '$bold',
  color: '#84818a',
});

export const SquareIcon = styled('span', {
  display: 'inline-block',
  width: '$3',
  height: '$3',
  borderRadius: '$1',
  backgroundColor: '$gray600',
});

export const StatusBox = styled(Box, {
  p: '$sm',
  py: '$1-5',
  whiteSpace: 'nowrap',
  backgroundColor: '#fbfafc',
  borderRadius: '$default',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$sm',

  variants: {
    transparent: {
      true: {
        backgroundColor: 'transparent',
      },
    },
    status: {
      notStarted: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '$gray600',
        },
      },
      inProgress: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '#00a5ff',
        },
      },
      published: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '#20c9ac',
        },
      },
    },
    isButton: {
      true: {
        cursor: 'pointer',
        border: 0,
        transition: '$quickly-ease',

        '&:hover:not(:disabled)': {
          backgroundColor: '$gray300',
        },

        '&:active:not(:disabled)': {
          backgroundColor: '$gray410',
        },

        '&:disabled': {
          opacity: 0.6,
          cursor: 'not-allowed',
        },
      },
    },
    active: {
      true: {
        backgroundColor: '$gray410',
      },
    },
  },
});
