import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';

import { useToast } from '~/app/contexts/ToastContext';
import { rewardsApi } from '~/shared/services/api';

function updateLanguage(languageId: string) {
  return rewardsApi.put(`employees/preferred-language/${languageId}`);
}

export function useUpdateLanguage() {
  const { t } = useTranslate();
  const toast = useToast();

  return useMutation({
    mutationFn: updateLanguage,
    onMutate: () => {
      toast.add({
        description: t('languages.preferred_language.updating'),
        type: 'neutral',
      });
    },
    onSuccess: () => {
      toast.add({
        type: 'success',
        description: t('languages.preferred_language.success'),
      });
    },
    onError: async () => {
      toast.add({
        type: 'error',
        description: t('languages.preferred_language.error'),
      });
    },
  });
}
