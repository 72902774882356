import {
  StyledTabsContent,
  StyledTabsList,
  StyledTabsRoot,
  StyledTabsTrigger,
  StyledTabsTriggerV2,
} from './styles';

export const TabsRoot = StyledTabsRoot;
export const TabsContent = StyledTabsContent;
export const TabsList = StyledTabsList;
export const TabsTrigger = StyledTabsTrigger;
export const TabsTriggerV2 = StyledTabsTriggerV2;
