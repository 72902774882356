export const ProcessConfiguration = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 16.3833H8.74829C8.78578 16.5518 8.8307 16.7169 8.88318 16.8783C8.89097 16.9023 8.89889 16.9262 8.90692 16.95H4.16667C3.85536 16.95 3.60256 16.8456 3.37849 16.6215C3.15443 16.3974 3.05 16.1446 3.05 15.8333V4.16667C3.05 3.85536 3.15443 3.60256 3.37849 3.37849C3.60256 3.15443 3.85536 3.05 4.16667 3.05H15.8333C16.1446 3.05 16.3974 3.15443 16.6215 3.37849C16.8456 3.60256 16.95 3.85536 16.95 4.16667V8.93475C16.9192 8.92431 16.8884 8.91406 16.8575 8.90401C16.7013 8.85327 16.5433 8.80946 16.3833 8.7726V4.16667V3.61667H15.8333H4.16667H3.61667V4.16667V9.16667V9.22917V15V15.8333V16.3833H4.16667ZM8.90401 13.1425C8.85327 13.2987 8.80946 13.4567 8.7726 13.6167H6.38333V13.05H8.93453C8.92423 13.0808 8.91406 13.1116 8.90401 13.1425ZM11.4437 9.71667C11.1847 9.88868 10.9382 10.0776 10.7043 10.2833H6.38333V9.71667H11.4437ZM13.6167 6.38333V6.95H6.38333V6.38333H13.6167Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.1"
    />
    <path
      d="M14.4558 16.9755L14.3984 16.6883L14.128 16.5756C13.9809 16.5143 13.8453 16.4478 13.7206 16.3766C13.601 16.3083 13.4822 16.2273 13.3643 16.133L13.1277 15.9438L12.8414 16.0425L12.0611 16.3116L11.6801 15.6544L12.2819 15.1194L12.5003 14.9253L12.4617 14.6356C12.4358 14.4419 12.4248 14.2906 12.4248 14.1771C12.4248 14.0636 12.4358 13.9123 12.4617 13.7185L12.5003 13.4289L12.2819 13.2348L11.6801 12.6998L12.0611 12.0426L12.8414 12.3116L13.1277 12.4104L13.3643 12.2211C13.4865 12.1233 13.6109 12.0355 13.7372 11.9572C13.8569 11.8831 13.987 11.8165 14.128 11.7577L14.3984 11.645L14.4558 11.3579L14.6174 10.55H15.3823L15.5439 11.3579L15.6013 11.645L15.8716 11.7577C16.0188 11.819 16.1544 11.8855 16.279 11.9567C16.3987 12.0251 16.5175 12.106 16.6354 12.2003L16.8719 12.3895L17.1583 12.2908L17.9386 12.0217L18.3196 12.679L17.7178 13.2139L17.4994 13.4081L17.538 13.6977C17.5638 13.8915 17.5748 14.0427 17.5748 14.1563C17.5748 14.2698 17.5638 14.421 17.538 14.6148L17.4994 14.9044L17.7178 15.0986L18.3196 15.6335L17.935 16.2969L17.1502 16.0398L16.8676 15.9473L16.6354 16.133C16.5175 16.2273 16.3987 16.3083 16.279 16.3766C16.1544 16.4478 16.0188 16.5143 15.8716 16.5756L15.6013 16.6883L15.5439 16.9755L15.3823 17.7833H14.6174L14.4558 16.9755ZM14.9998 16.3833C15.6052 16.3833 16.1371 16.1614 16.5658 15.7327C16.9945 15.3039 17.2165 14.772 17.2165 14.1667C17.2165 13.5613 16.9945 13.0294 16.5658 12.6007C16.1371 12.172 15.6052 11.95 14.9998 11.95C14.3945 11.95 13.8626 12.172 13.4338 12.6007C13.0051 13.0294 12.7832 13.5613 12.7832 14.1667C12.7832 14.772 13.0051 15.3039 13.4338 15.7327C13.8626 16.1614 14.3945 16.3833 14.9998 16.3833Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.1"
    />
  </svg>
);
