import Script from 'next/script';
import type { JWT } from 'next-auth/jwt';

import { canDisplayNpsSurvey } from '~/shared/utils/environments';

type NpsMetricScriptProps = {
  session?: JWT | null;
};

const delightedId = process.env.NEXT_PUBLIC_DELIGHTED_ID;

function NpsMetricScript({ session }: NpsMetricScriptProps) {
  if (!canDisplayNpsSurvey()) {
    return null;
  }

  return (
    <Script
      id="delighted"
      dangerouslySetInnerHTML={{
        __html: `
          !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"${delightedId}","delightedNps3");
          delightedNps3.survey({
            email: "${session?.user?.email}",
            name: "${session?.user?.name}",
            properties: {
              Zona: '${session?.user?.zone}',
              Persona: '${session?.user?.persona}',
              GloalID: '${session?.user?.globalId}'
            },
          });
        `,
      }}
    />
  );
}

export const NPS = {
  Script: NpsMetricScript,
};
