import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability';

import { convertBandToNumber } from '../utils/band';

export enum Action {
  Create = 'create',
  Use = 'use',
  Proxy = 'proxy',
}

export enum Personas {
  Persona1 = 'PERSONA_1',
  Persona2 = 'PERSONA_2',
  Persona3 = 'PERSONA_3',
  Persona4 = 'PERSONA_4',
}

type Entities =
  | 'Knowledge'
  | 'PSU'
  | 'LTI'
  | 'User'
  | 'Rewards'
  | 'ShareElection'
  | 'Employee'
  | 'RoleSetting'
  | 'EquatePlus'
  | 'ProcessConfiguration'
  | 'ProcessConfigurationEdit'
  | 'BudgetAllocators'
  | 'Stellar'
  | 'Allocator'
  | 'ManageExtraIncentive'
  | 'ViewExtraIncentive'
  | 'AllocationTableOverwriteColumn'
  | 'SupOrgBudgetGlobal'
  | 'AllocationGraphsSummary'
  | 'AllocationGraphsByCycle'
  | 'SelectAllocation'
  | 'EditAllocation'
  | 'EditAllocationRestricted'
  | 'EditAllocationAfterCompleted'
  | 'EditAllocationTemplateStatement'
  | 'AllocationCommentsAdminOnly'
  | 'AllocationManagerLevel1Name'
  | 'ExportAllocation'
  | 'ExportAllocationGrid'
  | 'ImportAllocation'
  | 'MassActionAllocation'
  | 'SubmitAllocation'
  | 'ReopenAllocation'
  | 'HistoricalBonusElection'
  | 'ExportBudgetAllocators'
  | 'ExportProcessConfiguration'
  | 'EmployeeGlobalIdAllocationTable'
  | 'AllocationTalentCardAndDashboard'
  | 'VisibilityFieldPriority'
  | 'MultiSelectZones';

export type AppAbility = PureAbility<
  [`${Action}`, Entities | Record<string, unknown>]
>;

function getUser(user?: any) {
  if (!user) {
    return null;
  }

  const finalUser = user.proxiedAs || user;

  return {
    ...finalUser,
    roles: finalUser.roles,
    band: convertBandToNumber(finalUser.band || ''),
  };
}

export function createUserAbility(user: any) {
  const { can, build } = new AbilityBuilder(
    PureAbility as AbilityClass<AppAbility>,
  );

  const checkUser = getUser(user);
  const rootUserRoles = user?.roles;

  if (!checkUser) {
    const ability = build();
    return ability;
  }

  if (
    rootUserRoles?.isRewardsGlobalAdmin ||
    rootUserRoles?.isRewardsZoneAdmin
  ) {
    can(Action.Proxy, 'User');
  }

  if (checkUser.roles?.isRewardsGlobalAdmin) {
    can(Action.Use, 'EditAllocationAfterCompleted');
    can(Action.Use, 'EditAllocationTemplateStatement');
    can(Action.Use, 'ReopenAllocation');
  }

  if (
    checkUser.roles?.isRewardsGlobalAdmin ||
    checkUser.roles?.isRewardsZoneAdmin
  ) {
    can(Action.Use, 'ProcessConfiguration');
    can(Action.Use, 'BudgetAllocators');
    can(Action.Use, 'SelectAllocation');
    can(Action.Use, 'AllocationCommentsAdminOnly');
    can(Action.Use, 'AllocationManagerLevel1Name');
    can(Action.Use, 'ExportAllocationGrid');
    can(Action.Use, 'ExportAllocation');
    can(Action.Use, 'AllocationGraphsSummary');
    can(Action.Use, 'EditAllocation');
    can(Action.Use, 'MassActionAllocation');
    can(Action.Use, 'HistoricalBonusElection');
    can(Action.Use, 'ExportBudgetAllocators');
    can(Action.Use, 'ExportProcessConfiguration');
    can(Action.Use, 'EmployeeGlobalIdAllocationTable');
    can(Action.Use, 'VisibilityFieldPriority');
    can(Action.Use, 'ViewExtraIncentive');
  }

  if (checkUser.roles?.isRewardsGlobalAdmin) {
    can(Action.Use, 'RoleSetting');
    can(Action.Use, 'ProcessConfigurationEdit');
    can(Action.Use, 'ImportAllocation');
    can(Action.Create, 'Knowledge');
    can(Action.Use, 'Stellar');
    can(Action.Use, 'AllocationTableOverwriteColumn');
    can(Action.Use, 'SupOrgBudgetGlobal');
    can(Action.Use, 'ManageExtraIncentive');
    can(Action.Use, 'MultiSelectZones');
  }

  if (
    checkUser.roles?.isRewardsZoneAdmin ||
    checkUser.roles?.isRewardsGlobalAdmin ||
    checkUser.roles?.isRewardsLtiAllocator
  ) {
    can(Action.Use, 'Allocator');
  }

  if (rootUserRoles?.isRewardsLtiAllocator) {
    can(Action.Use, 'AllocationTalentCardAndDashboard');
  }

  if (
    checkUser.roles?.isRewardsLtiAllocator &&
    !(
      checkUser.roles?.isRewardsGlobalAdmin ||
      checkUser.roles?.isRewardsZoneAdmin
    )
  ) {
    can(Action.Use, 'AllocationGraphsByCycle');
    can(Action.Use, 'EditAllocationRestricted');
    can(Action.Use, 'SubmitAllocation');
  }

  if (checkUser.band < 5) {
    can(Action.Use, 'Knowledge');
    can(Action.Use, 'EquatePlus');
  }

  if (
    checkUser.persona === Personas.Persona1 ||
    checkUser.persona === Personas.Persona2 ||
    checkUser.persona === Personas.Persona3
  ) {
    can(Action.Use, 'Rewards');
  }

  if (checkUser.persona === Personas.Persona1) {
    can(Action.Use, 'PSU');
  }

  if (
    checkUser.persona === Personas.Persona2 ||
    checkUser.persona === Personas.Persona3
  ) {
    can(Action.Use, 'LTI');
  }

  if (
    checkUser.persona !== Personas.Persona3 &&
    checkUser.persona !== Personas.Persona4
  ) {
    can(Action.Use, 'ShareElection');
  }

  if (checkUser.persona === Personas.Persona4) {
    can(Action.Use, 'Employee');
  }

  const ability = build();
  return ability;
}
