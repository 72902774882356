import { useEffect, useState } from 'react';

export function useMenuFooterHeight() {
  const [footer, setFooter] = useState<HTMLDivElement | null>(null);

  const [footerHeight, setFooterHeight] = useState<number | null>(null);

  const footerRef = (node: HTMLDivElement | null) => {
    setFooter(node);
  };

  useEffect(() => {
    if (footer) {
      setFooterHeight(footer.offsetHeight);
    }
  }, [footer]);

  return { footerRef, footerHeight };
}
