export const InformationIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.23254 8.6001V12.6001"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.23254 5.40806L9.24054 5.39917"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.23248 17C13.6507 17 17.2325 13.4182 17.2325 9C17.2325 4.58172 13.6507 1 9.23248 1C4.8142 1 1.23248 4.58172 1.23248 9C1.23248 13.4182 4.8142 17 9.23248 17Z"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
