const romanNumerals: Record<string, number> = {
  I: 1,
  V: 5,
  X: 10,
  L: 50,
  C: 100,
  D: 500,
  M: 1000,
};

export const convertBandToNumber = (band: string): number => {
  let result = 0;
  let i;
  const roman = band.toUpperCase().split('');

  for (i = 0; i < roman.length; i += 1) {
    const currentSymbolValue = romanNumerals[roman[i]];

    if (i + 1 < roman.length) {
      const nextSymbolValue = romanNumerals[roman[i + 1]];
      if (currentSymbolValue >= nextSymbolValue) {
        result += currentSymbolValue;
      } else {
        result = result + nextSymbolValue - currentSymbolValue;
        i += 1;
      }
    } else {
      result += currentSymbolValue;
    }
  }

  return result;
};
