export const CalculatorIcon = () => {
  return (
    <svg
      viewBox="0 0 35 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6786 2.85714V2.60714H31.4286H2.85714H2.60714V2.85714V37.1429V37.3929H2.85714H31.4286H31.6786V37.1429V2.85714ZM2.85714 0.25H31.4286C32.12 0.25 32.7832 0.52468 33.2721 1.01361C33.761 1.50255 34.0357 2.16569 34.0357 2.85714V37.1429C34.0357 37.8343 33.761 38.4975 33.2721 38.9864C32.7832 39.4753 32.12 39.75 31.4286 39.75H2.85714C2.16568 39.75 1.50255 39.4753 1.01361 38.9864C0.52468 38.4975 0.25 37.8343 0.25 37.1429V2.85714C0.25 2.16569 0.52468 1.50255 1.01361 1.01361C1.50255 0.52468 2.16569 0.25 2.85714 0.25Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M7.14062 30.0002H9.99777V32.8574H7.14062V30.0002ZM24.2835 30.0002H27.1406V32.8574H24.2835V30.0002ZM7.14062 22.8574H9.99777V25.7145H7.14062V22.8574ZM24.2835 22.8574H27.1406V25.7145H24.2835V22.8574ZM7.14062 15.7145H9.99777V18.5716H7.14062V15.7145ZM15.7121 30.0002H18.5692V32.8574H15.7121V30.0002ZM15.7121 22.8574H18.5692V25.7145H15.7121V22.8574ZM15.7121 15.7145H18.5692V18.5716H15.7121V15.7145ZM24.2835 15.7145H27.1406V18.5716H24.2835V15.7145ZM7.14062 7.14307H27.1406V11.4288H7.14062V7.14307Z"
        fill="currentColor"
      />
    </svg>
  );
};
