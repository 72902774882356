import * as React from 'react';

type TargetProps = {
  size?: number;
  color?: string;
};

export function Target({ size = 24, color = '#ffffff' }: TargetProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      alignmentBaseline="middle"
      fill="none"
      viewBox="0 0 16 16"
      color={color}
    >
      <path
        fill="currentColor"
        d="M13.867 5.197a6.507 6.507 0 11-1.637-2.135l1.417-1.417a.5.5 0 01.707.707l-6 6a.5.5 0 11-.707-.707l1.732-1.733a2.5 2.5 0 101.117 1.943.501.501 0 011-.056 3.5 3.5 0 11-1.399-2.603l1.422-1.422a5.492 5.492 0 101.446 1.855.5.5 0 11.902-.432z"
      />
    </svg>
  );
}
