import { useEffect, useState } from 'react';
import { Box, CSS, styled, Text } from '@ghq-abi/design-system';

const Bubble = styled(Box, {
  position: 'absolute',
  // top: '-50%',
  bottom: '-150%',
  transition: 'opacity 0.3s',

  span: {
    width: 28,
    height: 28,
    lineHeight: '28px',
    textAlign: 'center',
    background: '$blue350',
    color: '#fff',
    fontSize: 12,
    fontWeight: '500',
    display: 'block',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -20px)',
    borderRadius: '50%',

    '&:before': {
      content: '',
      position: 'absolute',
      width: 0,
      height: 0,
      borderTop: '10px solid $blue350',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      top: '100%',
      left: '50%',
      marginLeft: '-10px',
      marginTop: '-34px',
      transform: 'rotate(180deg)',
    },
  },
});

type SliderBubbleProps = {
  value: number;
  min: number;
  max: number;
  visible: boolean;
  css?: CSS;
};

export const SliderBubble = ({
  value,
  min,
  max,
  visible,
  css,
}: SliderBubbleProps) => {
  const [left, setLeft] = useState('');

  useEffect(() => {
    const newValue = Number(((value - min) * 100) / (max - min));
    const newPosition = 10 - newValue * 0.2;

    setLeft(`calc(${newValue}% + (${newPosition}px))`);
  }, [value, min, max]);

  return (
    <Bubble
      css={{
        left,
        opacity: visible ? 1 : 0,
        ...css,
      }}
    >
      <Text as="span">{value}</Text>
    </Bubble>
  );
};
