import { css, theme } from '@ghq-abi/design-system';

import { fonts } from '~/shared/constants/fonts';

export const wrapperStyles = css({
  // TODO: UX replace with fontFamily: theme.fonts.openSans after DS update,
  fontFamily: fonts.openSans,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginRight: theme.space.sm.value,
});

export const triggerStyles = css({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // TODO: UX replace with fontFamily: theme.fonts.openSans after DS update,
  fontFamily: fonts.openSans,
  borderRadius: theme.radii.default,
  py: theme.space[3].value,
  px: theme.space.md.value,
  fontSize: theme.fontSizes.sm,
  fontWeight: theme.fontWeights.medium,
  backgroundColor: theme.colors.white,
  color: theme.colors.gray750,
  border: `1px solid ${theme.colors.gray410}`,

  '&:focus': { boxShadow: `0 0 0 2px ${theme.colors.gray750}` },

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.6,
  },

  variants: {
    isEmpty: {
      true: { color: theme.colors.gray550 },
    },
  },
});

export const iconStyles = css({
  color: theme.colors.gray550,
  display: 'flex',
});

export const contentStyles = css({
  overflow: 'hidden',
  backgroundColor: theme.colors.white,
  // TODO: UX Replace with var after DS udpate
  // fontFamily: '$openSans',
  fontFamily: 'OpenSans, sans-serif !important',
  borderRadius: theme.radii.default,
  border: `1px solid ${theme.colors.gray410}`,
  zIndex: theme.zIndices.tooltip,
  py: theme.space.md.value,
  px: theme.space.sm.value,
  display: 'none',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 44,

  variants: {
    isOpen: {
      true: {
        display: 'block',
        overflowY: 'scroll',
        maxHeight: '300px',
      },
    },
  },
});

export const itemStyles = css({
  // TODO: UX replace with fontFamily: theme.fonts.openSans after DS update,
  fontFamily: fonts.openSans,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: theme.radii.default,
  px: theme.space.sm.value,
  py: theme.space.sm.value,
  transition: 'background-color 100ms linear',
  fontSize: theme.fontSizes.sm,
  color: theme.colors.gray750,
  userSelect: 'none',
  gap: theme.space['2-5'],

  '&:hover, &:focus': {
    backgroundColor: theme.colors.gray300,
  },

  '&:focus:not(:hover)': {
    boxShadow: `0 0 0 2px ${theme.colors.gray750}`,
  },

  '& + &': {
    mt: theme.space.sm,
  },
});
