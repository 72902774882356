import { useMemo } from 'react';
import {
  CashCoinIcon,
  HouseIcon,
  MenuCalculatorIcon,
  MenuGearIcon,
  MenuMoneyBagIcon,
  MenuQuestionCircleIcon,
  WalletIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { RouteObject } from '~/app/types';
import { useUser } from '~/shared/auth/useUser';
import { EquatePlus } from '~/shared/components/icons';

export function useMenuRoutes() {
  const { t } = useTranslate();
  const ability = useAbility();
  const { isProxying } = useUser();
  const { menuRoutes, footerRoutes } = useMemo(() => {
    const menuRoutesList: RouteObject[] = [];
    const footerRoutesList: RouteObject[] = [];

    if (ability.can('use', 'Rewards')) {
      menuRoutesList.push({
        key: 'home',
        icon: <HouseIcon />,
        path: '/home',
        title: t('menu.home'),
      });

      menuRoutesList.push({
        key: 'myTotalCompensation',
        icon: <MenuCalculatorIcon />,
        path: '/my-total-compensation',
        title: t('my_total_compensation.title'),
      });

      menuRoutesList.push({
        key: 'bonusSimulator',
        icon: <MenuMoneyBagIcon />,
        path: '/bonus-simulator',
        title: t('menu.bonus_simulator'),
      });

      menuRoutesList.push({
        key: 'psuSimulator',
        icon: <WalletIcon />,
        path: '/psu-simulator',
        title: t('home.my_lti.title'),
      });
    }

    if (ability.can('use', 'Employee')) {
      menuRoutesList.push({
        key: 'bonusSimulator',
        icon: <MenuMoneyBagIcon />,
        path: '/bonus-simulator',
        title: t('menu.bonus_simulator'),
      });
    }

    if (ability.can('use', 'RoleSetting')) {
      menuRoutesList.push({
        key: 'roleSetting',
        icon: <MenuGearIcon />,
        path: '/role-setting',
        title: t('menu.role_setting'),
      });
    }

    if (
      ability.can('use', 'ProcessConfiguration') ||
      ability.can('use', 'BudgetAllocators') ||
      ability.can('use', 'Allocator')
    ) {
      const ltiProcessComponents = [];

      if (ability.can('use', 'ProcessConfiguration')) {
        ltiProcessComponents.push({
          key: 'processConfiguration',
          path: '/process-configuration',
          title: t('home.process_configuration.title'),
        });
      }

      if (ability.can('use', 'BudgetAllocators')) {
        ltiProcessComponents.push({
          key: 'budgetAllocators',
          path: '/budget-allocators',
          title: t('home.budget_allocators.title'),
        });
      }

      if (ability.can('use', 'Allocator')) {
        ltiProcessComponents.push({
          key: 'allocation',
          path: '/allocation',
          title: t('home.allocation.title'),
        });
      }

      menuRoutesList.push({
        key: 'ltiProcess',
        icon: <CashCoinIcon />,
        path: '/',
        title: t('menu.lti_process'),
        routeComponents: ltiProcessComponents,
      });
    }

    if (ability.can('use', 'Knowledge')) {
      footerRoutesList.push({
        key: 'knowledgeCenter',
        icon: <MenuQuestionCircleIcon />,
        path: '/knowledge',
        title: t('menu.knowledge_center'),
      });
    }

    if (!isProxying && ability.can('use', 'EquatePlus')) {
      footerRoutesList.push({
        key: 'equatePlus',
        icon: <EquatePlus />,
        path:
          process.env.NEXT_PUBLIC_EQUATEPLUS_LINK ||
          'https://www.equateplus.com',
        title: t('menu.equate_plus'),
      });
    }

    return { menuRoutes: menuRoutesList, footerRoutes: footerRoutesList };
  }, [ability, isProxying, t]);

  return { menuRoutes, footerRoutes };
}
