import { useSessionInfo } from '~/app/contexts/SessionInfoContext';

export function useUser() {
  const { sessionInfo } = useSessionInfo();

  const user = sessionInfo?.proxiedAs || sessionInfo;

  return {
    name: user?.name,
    zone: user?.zone,
    globalId: user?.globalId,
    employeeId: user?.employeeId,
    managedZones: user?.managedZones,
    roles: user?.roles,
    language: user?.language,
    persona: user?.persona,
    isProxying: !!sessionInfo?.proxiedAs,
    band: user?.band,
  };
}

export type User = ReturnType<typeof useUser>;
