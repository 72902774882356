import type { QueryClientConfig } from '@tanstack/react-query';

import { isProduction } from '../utils/environments';

const FIVE_MINUTES = 300_000;
const THIRTY_SECONDS = 30_000;

const staleTime = isProduction() ? FIVE_MINUTES : THIRTY_SECONDS;

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};
