import * as React from 'react';

import {
  EditAllocatorsPayload,
  ExtraIncentivePayload,
  GetBudgetAllocatorsAPIResponse,
} from '~/entities/budget-allocators/types';
import * as EmployeeEntity from '~/entities/Employee';
import { GetLtiCyclesAPIResponse } from '~/entities/lti-cycle';

type BudgetAndAllocatorsContextData = {
  budgetsGlobal: GetBudgetAllocatorsAPIResponse | undefined;
  setBudgetsGlobal: (value: GetBudgetAllocatorsAPIResponse | undefined) => void;

  openedModal: boolean;
  setOpenedModal: React.Dispatch<React.SetStateAction<boolean>>;

  addedOrRemovedAllocators: EditAllocatorsPayload;
  setAddedOrRemovedAllocators: React.Dispatch<
    React.SetStateAction<EditAllocatorsPayload>
  >;
  currentSupOrg: string;
  setCurrentSupOrg: React.Dispatch<React.SetStateAction<string>>;
  allocatorsBySuporg: { [key: string]: EmployeeEntity.Employee[] };
  setAllocatorsBySuporg: React.Dispatch<
    React.SetStateAction<{ [key: string]: EmployeeEntity.Employee[] }>
  >;
  currentEditedSuporg: string;
  setCurrentEditedSuporg: React.Dispatch<React.SetStateAction<string>>;
  openedSaveModal: boolean;
  setOpenedSaveModal: React.Dispatch<React.SetStateAction<boolean>>;
  previousEditedSuporg: string;
  setPreviousEditedSuporg: React.Dispatch<React.SetStateAction<string>>;
  openedExtraIncentiveModal: boolean;
  setOpenedExtraIncentiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  openedExportModal: boolean;
  setOpenedExportModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentExtraIncentives: { [key: string]: ExtraIncentivePayload };
  setCurrentExtraIncentives: React.Dispatch<
    React.SetStateAction<{ [key: string]: ExtraIncentivePayload }>
  >;
  currentExtraIncentivesGlobalBudget: { [key: string]: ExtraIncentivePayload };
  setCurrentExtraIncentivesGlobalBudget: React.Dispatch<
    React.SetStateAction<{ [key: string]: ExtraIncentivePayload }>
  >;
  searchEmployeeText: string;
  setSearchEmployeeText: React.Dispatch<React.SetStateAction<string>>;
  displayedCycle: GetLtiCyclesAPIResponse | undefined;
  setDisplayedCycle: React.Dispatch<
    React.SetStateAction<GetLtiCyclesAPIResponse | undefined>
  >;
};

export const BudgetAndAllocatorsContext = React.createContext(
  {} as BudgetAndAllocatorsContextData,
);

type BudgetAndAllocatorsProps = {
  children: React.ReactNode;
};

export function BudgetAndAllocatorsProvider({
  children,
}: BudgetAndAllocatorsProps) {
  const [budgetsGlobal, setBudgetsGlobal] =
    React.useState<GetBudgetAllocatorsAPIResponse>();
  const [openedModal, setOpenedModal] = React.useState<boolean>(false);
  const [addedOrRemovedAllocators, setAddedOrRemovedAllocators] =
    React.useState<EditAllocatorsPayload>({});
  const [currentSupOrg, setCurrentSupOrg] = React.useState<string>('');
  const [allocatorsBySuporg, setAllocatorsBySuporg] = React.useState(
    {} as { [key: string]: EmployeeEntity.Employee[] },
  );
  const [currentEditedSuporg, setCurrentEditedSuporg] =
    React.useState<string>('REFETCH');
  const [previousEditedSuporg, setPreviousEditedSuporg] =
    React.useState<string>('REFETCH');

  const [openedSaveModal, setOpenedSaveModal] = React.useState<boolean>(false);
  const [openedExtraIncentiveModal, setOpenedExtraIncentiveModal] =
    React.useState<boolean>(false);
  const [openedExportModal, setOpenedExportModal] =
    React.useState<boolean>(false);

  const [currentExtraIncentives, setCurrentExtraIncentives] = React.useState<{
    [key: string]: ExtraIncentivePayload;
  }>({});
  const [
    currentExtraIncentivesGlobalBudget,
    setCurrentExtraIncentivesGlobalBudget,
  ] = React.useState<{
    [key: string]: ExtraIncentivePayload;
  }>({});
  const [searchEmployeeText, setSearchEmployeeText] =
    React.useState<string>('');

  const [displayedCycle, setDisplayedCycle] = React.useState<
    GetLtiCyclesAPIResponse | undefined
  >();

  const providerValue = React.useMemo(
    () => ({
      budgetsGlobal,
      setBudgetsGlobal,
      openedModal,
      setOpenedModal,
      addedOrRemovedAllocators,
      setAddedOrRemovedAllocators,
      currentSupOrg,
      setCurrentSupOrg,
      allocatorsBySuporg,
      setAllocatorsBySuporg,
      currentEditedSuporg,
      setCurrentEditedSuporg,
      openedSaveModal,
      setOpenedSaveModal,
      previousEditedSuporg,
      setPreviousEditedSuporg,
      openedExtraIncentiveModal,
      setOpenedExtraIncentiveModal,
      openedExportModal,
      setOpenedExportModal,
      currentExtraIncentives,
      setCurrentExtraIncentives,
      currentExtraIncentivesGlobalBudget,
      setCurrentExtraIncentivesGlobalBudget,
      searchEmployeeText,
      setSearchEmployeeText,
      displayedCycle,
      setDisplayedCycle,
    }),
    [
      budgetsGlobal,
      setBudgetsGlobal,
      openedModal,
      setOpenedModal,
      addedOrRemovedAllocators,
      setAddedOrRemovedAllocators,
      currentSupOrg,
      setCurrentSupOrg,
      allocatorsBySuporg,
      setAllocatorsBySuporg,
      currentEditedSuporg,
      setCurrentEditedSuporg,
      openedSaveModal,
      setOpenedSaveModal,
      previousEditedSuporg,
      setPreviousEditedSuporg,
      openedExtraIncentiveModal,
      setOpenedExtraIncentiveModal,
      openedExportModal,
      setOpenedExportModal,
      currentExtraIncentives,
      setCurrentExtraIncentives,
      currentExtraIncentivesGlobalBudget,
      setCurrentExtraIncentivesGlobalBudget,
      searchEmployeeText,
      setSearchEmployeeText,
      displayedCycle,
      setDisplayedCycle,
    ],
  );

  return (
    <BudgetAndAllocatorsContext.Provider value={providerValue}>
      {children}
    </BudgetAndAllocatorsContext.Provider>
  );
}

export function useBudgetAndAllocators() {
  const context = React.useContext(BudgetAndAllocatorsContext);

  if (!context) {
    throw new Error(
      'useBudgetAndAllocators must be used within a BudgetAndAllocatorsProvider',
    );
  }

  return context;
}
