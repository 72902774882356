import { useSessionInfo } from '~/app/contexts/SessionInfoContext';

import { useUpdateLanguage } from '../api';

export const useLanguageDropdown = () => {
  const updateLanguage = useUpdateLanguage();
  const { updateCurrentLanguage } = useSessionInfo();

  function handleLanguageChange(newLanguage: string) {
    updateLanguage.mutate(newLanguage, {
      onSuccess: async () => {
        if (updateCurrentLanguage) {
          updateCurrentLanguage(newLanguage);
        }
      },
    });
  }

  return {
    updateLanguage,
    handleLanguageChange,
  };
};
