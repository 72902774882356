import * as React from 'react';

type UserCheckProps = {
  size?: number;
  color?: string;
};

export function UserCheck({ size = 24, color = '#ffffff' }: UserCheckProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      color={color}
    >
      <path
        fill="#171717"
        d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm1.679-4.493l-1.335 2.226a.751.751 0 01-1.174.144l-.774-.773a.5.5 0 01.708-.708l.547.548 1.17-1.951a.5.5 0 11.858.514zM11 5a3 3 0 11-6 0 3 3 0 016 0zM8 7a2 2 0 100-4 2 2 0 000 4z"
      />
      <path
        fill="#171717"
        d="M8.256 14a4.474 4.474 0 01-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256z"
      />
    </svg>
  );
}
