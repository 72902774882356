import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { JWT } from 'next-auth/jwt';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID ?? '';

type EventsEntities = 'Nominations' | 'Evaluation' | 'Manager_Approval';

type AnalyticEvents =
  | 'Evaluation_access'
  | 'Evaluation_edit'
  | 'Evaluation_decline_click'
  | 'Evaluation_declined_confirmation'
  | 'Evaluation_edit_assessment'
  | 'Evaluation_submit_assessment'
  | 'Evaluation_save_close'
  | 'Evaluation_rating_scale'
  | 'Evaluation_take_survey'
  | 'Evaluation_overview_click'
  | 'Evaluation_overview_edit'
  | 'Nominations_save'
  | 'Nominations_submit'
  | 'Nominations_edition'
  | 'Nominations_access'
  | 'Manager_Approval_submit'
  | 'Manager_Approval_feedback'
  | 'Manager_Approval_approval'
  | 'Manager_Approval_access';

type GTagEvent = {
  action: AnalyticEvents;
  category: EventsEntities;
  label: string;
  value: number;
};

function fireEvent({ action, category, label, value }: GTagEvent) {
  if (!GA_TRACKING_ID) return;

  if (!window.gtag) return;

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
}

function usePageView(session?: JWT | null) {
  const router = useRouter();

  useEffect(() => {
    const canLoadConfig =
      GA_TRACKING_ID && session?.user && typeof window.gtag !== 'undefined';

    function handleRouteChange(url: URL) {
      if (!canLoadConfig) {
        return;
      }

      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
        page_title: document.title,
        user_properties: {
          country: session?.user?.country,
          employeeId: session?.user?.employeeId,
          zone: session?.user?.zone,
          band: session?.user?.band,
        },
      });
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, session]);
}

type GoogleAnalyticsScriptProps = {
  session?: JWT | null;
};

function GoogleAnalyticsScript({ session }: GoogleAnalyticsScriptProps) {
  usePageView(session);

  if (!session) return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        `}
      </Script>
    </>
  );
}

export const GA = {
  fireEvent,
  Script: GoogleAnalyticsScript,
};
