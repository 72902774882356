import { Flex, Skeleton } from '@ghq-abi/design-system';

export function Loading() {
  return (
    <Flex align="center" css={{ gap: '$md' }}>
      <Skeleton variant="circle" size={12} />
      <Skeleton
        variant="rectangle"
        height={94}
        css={{ m: 0, width: '$full' }}
      />
    </Flex>
  );
}
