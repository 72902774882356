import { ModuleDropdownProps } from '@ghq-abi/design-system';
import {
  ClipboardCheckLargeIcon,
  PersonBadgeIcon,
  TargetIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

import { useUser } from '~/shared/auth/useUser';
import { Icon } from '~/shared/components';

const style = {
  color: '#191F2E',
  size: 16,
};

export function useModuleItems() {
  const { t } = useTranslate();

  const { zone } = useUser();

  const zones = [
    'ZONE SOUTH AMERICA',
    'ZONE MIDDLE AMERICAS',
    'GLOBAL',
    'ZONE EUROPE',
  ];
  const checkZones = zones.includes(zone || '');

  const items: ModuleDropdownProps['items'] = [
    {
      label: t('menu.my_180_360'),
      subtitle: t('menu.lcm'),
      icon: <ClipboardCheckLargeIcon width="16px" />,
      url: process.env.NEXT_PUBLIC_MY_180_360_LINK,
    },
    {
      label: t('menu.my_talent_card'),
      subtitle: t('menu.opr'),
      icon: <PersonBadgeIcon width="16px" />,
      url: process.env.NEXT_PUBLIC_OPR_LINK,
    },
    {
      label: t('menu.my_targets'),
      subtitle: t('menu.northstar'),
      icon: <TargetIcon width="16px" />,
      url: process.env.NEXT_PUBLIC_TSC_LINK,
    },
  ];

  if (checkZones) {
    items.unshift({
      label: t('menu.cheershub'),
      subtitle: t('menu.cheershub'),
      icon: <Icon.Cheershub color={style.color} size={style.size} />,
      url: process.env.NEXT_PUBLIC_CHEERSHUB_LINK,
    });
  }

  return { items };
}
