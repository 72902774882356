import { FC } from 'react';

type EditIconProps = {
  size?: number;
  color?: string;
};

export const EditIcon: FC<EditIconProps> = ({ size = 16, color = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2034_66392)">
        <path
          fill={color}
          d="M15.053 3.698l-2.609-2.623a.92.92 0 00-1.297 0l-9.25 9.236-.844 3.644a.916.916 0 00.89 1.111.939.939 0 00.19 0l3.685-.844 9.235-9.227a.92.92 0 000-1.297zm-9.68 9.724l-3.453.724.787-3.386 6.92-6.893 2.666 2.666-6.92 6.89zM12.89 5.89l-2.667-2.667 1.547-1.538 2.622 2.667-1.502 1.538z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2034_66392">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
