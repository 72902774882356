import * as React from 'react';
import { ProfileDropdown } from '@ghq-abi/design-system';

import { useUserDropdown } from './hooks';
import { HeaderProps } from './types';

export function UserDropdown({ user, isProxying }: HeaderProps) {
  const { items, profileInfo } = useUserDropdown({ user, isProxying });

  return <ProfileDropdown items={items} profileInfo={profileInfo} />;
}
