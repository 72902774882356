import {
  authPublicApi,
  mountHTTPInstance,
  peoplePlatformApi,
} from 'abi-people-platform-auth-client-lib';

export const rewardsApi = mountHTTPInstance({
  baseURL: process.env.NEXT_PUBLIC_REWARDS_API_URL,
});

export { authPublicApi, peoplePlatformApi };
