import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';

import { useUser } from '~/shared/auth/useUser';
import {
  CheersHubIcon,
  OtherAppsIcon,
  OurAppsIcon,
} from '~/shared/components/icons';
import { rewardsApi } from '~/shared/services/api';

import { useMenuRoutes } from '../components/Nav/hooks/useMenuRoutes';
import { RouteObject } from '../types';

type NavContextData = {
  isAppMenuOpen: boolean;
  subMenuOpen?: RouteObject | null;
  menuHeaderItem: RouteObject;
  menuItems: RouteObject[];
  footerItems: RouteObject[];
  toggleAppMenu: () => void;
  toggleSubMenu: (item: RouteObject | null) => void;
  checkIfIsActive: (menuRoute: string, subitems?: RouteObject[]) => boolean;
};

const NavContext = React.createContext({} as NavContextData);

type NavProviderProps = {
  children: React.ReactNode;
};

export function NavProvider({ children }: NavProviderProps) {
  const { t } = useTranslate('default');

  const router = useRouter();
  const { zone } = useUser();

  const [firstLtiSnapshot, setFirstLtiSnapshot] = React.useState<Date>();
  const [isAppMenuOpen, setIsAppMenuOpen] = React.useState(false);
  const [subMenuOpen, setSubmenuOpen] = React.useState<RouteObject | null>();
  const [filteredMenuItem, setFilteredMenuItem] = React.useState<RouteObject[]>(
    [],
  );
  const { menuRoutes, footerRoutes } = useMenuRoutes();

  React.useEffect(() => {
    const getData = async () => {
      const { data } = await rewardsApi.get<Date>(
        '/lti-cycle/first-snapshot-date',
      );
      setFirstLtiSnapshot(data);
    };
    getData();
  }, []);

  React.useEffect(() => {
    const cloneMenuRoutes = menuRoutes.map(e => {
      if (e.key !== 'ltiProcess') return { ...e };
      return {
        ...e,
        routeComponents: e.routeComponents?.filter(
          s =>
            s.key !== 'allocation' ||
            (firstLtiSnapshot && new Date(firstLtiSnapshot) <= new Date()),
        ),
      };
    });
    setFilteredMenuItem(cloneMenuRoutes);
  }, [menuRoutes, firstLtiSnapshot]);

  const zones = ['ZONE SOUTH AMERICA', 'ZONE MIDDLE AMERICAS', 'GLOBAL'];
  const checkZones = zones.includes(zone || '');

  const toggleSubMenu = (item: RouteObject | null) => {
    setSubmenuOpen(item?.routeComponents?.length ? item : null);
  };

  const checkIfIsActive = React.useCallback(
    (menuRoute: string, subitems?: RouteObject[]) => {
      const hasSomeSubitemActive = subitems?.some(
        subitem => subitem.path === router.route,
      );

      return (
        (!!menuRoute && router.route === menuRoute) || !!hasSomeSubitemActive
      );
    },
    [router.route],
  );

  const ourApps: RouteObject[] = React.useMemo(() => {
    const values: RouteObject[] = [
      {
        key: 'lcm',
        title: '180/360',
        path: process.env.NEXT_PUBLIC_MY_180_360_LINK || '',
        icon: <OtherAppsIcon />,
      },
      {
        key: 'northstar',
        title: 'NorthStar',
        path: process.env.NEXT_PUBLIC_TSC_LINK || '',
        icon: <OtherAppsIcon />,
      },
      {
        key: 'opr',
        title: 'OPR',
        path: process.env.NEXT_PUBLIC_OPR_HOME_LINK || '',
        icon: <OtherAppsIcon />,
      },
      {
        key: 'workday',
        title: 'Workday',
        path: process.env.NEXT_PUBLIC_WORKDAY_URL || '',
        icon: <OtherAppsIcon />,
      },
    ];

    if (checkZones) {
      values.splice(1, 0, {
        key: 'cheersHub',
        title: 'CheersHub',
        path: process.env.NEXT_PUBLIC_CHEERSHUB_LINK || '',
        icon: <CheersHubIcon />,
      });
    }

    return values;
  }, [checkZones]);

  function toggleAppMenu() {
    setIsAppMenuOpen(state => !state);
  }

  const providerValue = React.useMemo(
    () => ({
      isAppMenuOpen,
      subMenuOpen,
      menuItems: filteredMenuItem,
      footerItems: footerRoutes,
      menuHeaderItem: {
        key: 'our_apps',
        title: t('common.menu_our_apps'),
        path: '/',
        icon: <OurAppsIcon />,
        routeComponents: ourApps,
      },
      toggleAppMenu,
      toggleSubMenu,
      checkIfIsActive,
    }),
    [
      isAppMenuOpen,
      subMenuOpen,
      filteredMenuItem,
      footerRoutes,
      t,
      ourApps,
      checkIfIsActive,
    ],
  );
  return (
    <NavContext.Provider value={providerValue}>{children}</NavContext.Provider>
  );
}

export function useNav() {
  const context = React.useContext(NavContext);

  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }

  return context;
}
