import { useEffect, useState } from 'react';

export function useMenuListScroll() {
  const [element, setElement] = useState<HTMLUListElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  function isScrollableContainer(currentElement: HTMLUListElement | null) {
    if (!currentElement) return false;

    return currentElement.scrollHeight > currentElement.clientHeight;
  }

  const elementRef = (node: HTMLUListElement | null) => {
    setElement(node);
  };

  useEffect(() => {
    function updateIsScrollable() {
      if (element) {
        setIsScrollable(isScrollableContainer(element));
      }
    }

    updateIsScrollable();
    window.addEventListener('resize', updateIsScrollable);

    return () => {
      window.removeEventListener('resize', updateIsScrollable);
    };
  }, [element]);

  return { elementRef, isScrollable };
}
