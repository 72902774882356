import { styled } from '@ghq-abi/design-system';
import * as Tabs from '@radix-ui/react-tabs';

export const StyledTabsRoot = styled(Tabs.Root, {
  // TODO: UX Replace with var after DS udpate
  // fontFamily: '$openSans',
  fontFamily: 'OpenSans, sans-serif !important',
});

export const StyledTabsList = styled(Tabs.List, {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '48px',
  borderBottom: '1px solid $gray400',
});

export const StyledTabsTrigger = styled(Tabs.Trigger, {
  flex: 1,
  display: 'flex',
  color: '$gray575',
  fontSize: '$sm',
  fontWeight: '$medium',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  background: '$white',
  paddingTop: '16px',
  paddingBottom: '12px',
  cursor: 'pointer',

  '&:hover': {
    background: '$white',
  },
  "&[data-state='active']": {
    color: '$gray750',
    borderBottom: '4px solid $yellow410',
  },
});

// TODO: UX - Use only StyledTabsTrigger with the new styles after all pages is updated
export const StyledTabsTriggerV2 = styled(StyledTabsTrigger, {
  // TODO: UX - Replace all colors with var after DS update
  gap: '$3',
  fontSize: '$md',
  backgroundColor: '$white',
  borderBottom: '1px solid #EEEFF2 !important',
  borderRadius: '4px 4px 0 0',
  color: '#B2B7C2',
  whiteSpace: 'nowrap',
  svg: {
    width: '$4',
    height: '$4',
    color: 'currentColor',
    path: {
      fill: 'currentColor',
      color: 'currentColor',
    },
  },

  "&[data-state='active']": {
    color: '#191F2E',
    boxShadow: 'inset 0 -2px 0 0 #F2DC39',
  },
});

export const StyledTabsContent = styled(Tabs.Content, {
  // TODO: UX Replace with var after DS udpate
  // fontFamily: '$openSans',
  fontFamily: 'OpenSans, sans-serif !important',
  color: '$gray750',

  '& dt': {
    marginTop: '30px',
    fontWeight: '$medium',
    fontSize: '$md',
  },

  '& dd': {
    marginTop: '15px',
    fontWeight: '$normal',
    fontSize: '$sm',
    textAlign: 'justify',
  },
});

export const SliderWrapper = styled('div', {
  display: 'flex',
  height: '100%',
  width: '100%',
});

export const SliderItems = styled('div', {
  display: 'flex',
  flex: 1,
});
