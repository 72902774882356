import React from 'react';
import { Box } from '@ghq-abi/design-system';

type BoxColorProps = {
  color?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  width?: string;
};

export const BoxColor = ({ color }: BoxColorProps) => {
  const renderColor = color || 'gray';

  return (
    <Box
      style={{
        height: 9,
        width: 9,
        paddingRight: 9,
        borderRadius: 2,
        backgroundColor: renderColor,
      }}
    />
  );
};

export const BoxColorLeft = ({
  color,
  children,
  width,
}: BoxColorProps & { children: React.ReactNode }) => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$1',
        width,
      }}
    >
      <BoxColor color={color} />
      {children}
    </Box>
  );
};
