import {
  DEFAULT_LANGUAGE,
  Language,
  REGISTERED_LANGUAGES,
} from '../constants/i18n';

export function capitalize(str: string) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function formatCurrency(num: number | undefined, useFraction = true) {
  if (num) {
    const splited = Number(num).toFixed(2).split('.');
    let integer = splited[0];
    const fraction = splited[1] || '00';
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${integer}${useFraction ? `,${fraction}` : ''}`;
  }
  return 0;
}

const isValidLanguage = (languageId: string): languageId is Language => {
  return REGISTERED_LANGUAGES.includes(languageId as Language);
};

export function normalizeLanguage(language?: string) {
  let normalizedLanguage = '';

  if (typeof language === 'string') {
    normalizedLanguage = language.replace('_', '-');
  }
  if (normalizedLanguage && isValidLanguage(normalizedLanguage)) {
    return normalizedLanguage;
  }

  return DEFAULT_LANGUAGE;
}

export function hashCode(param: string) {
  let hash = 0;
  let chr;

  if (param.length === 0) {
    return hash;
  }

  for (let i = 0; i < param.length; i += 1) {
    chr = param.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}
