import { Flex } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { useUser } from '~/shared/auth/useUser';

import { HeaderActions } from '../HeaderActions';
import { LanguageDropdownHeader } from '../LanguageDropdown';
import { ModulesDropdown } from '../ModulesDropdown';
import { UserDropdown } from '../UserDropdown';

import { StyledHeader } from './styles';

export function Header() {
  const user = useUser();

  const { isAppMenuOpen } = useNav();

  return (
    <StyledHeader isMenuOpen={isAppMenuOpen}>
      <HeaderActions />
      <Flex gap="md" align="center">
        <LanguageDropdownHeader currentLanguage={user.language} />
        <ModulesDropdown />
        {user && <UserDropdown user={user} isProxying={user.isProxying} />}
      </Flex>
    </StyledHeader>
  );
}
