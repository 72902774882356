import { MouseEvent, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Checkbox, Flex, Label, styled } from '@ghq-abi/design-system';

import {
  contentStyles,
  iconStyles,
  itemStyles,
  triggerStyles,
  wrapperStyles,
} from './styles';

const StyledWrapper = styled('div', wrapperStyles);

const StyledTrigger = styled('button', triggerStyles);

const StyledIcon = styled('span', iconStyles);

const StyledContent = styled('div', contentStyles);

const StyledItem = styled('div', itemStyles);

export type ItemType = {
  value: string;
  text: string;
  checked: boolean;
};

export type MultiselectProps = {
  id?: string;
  label?: string;
  disabled: boolean;
  placeholder: string;
  options: ItemType[];
  handleSelect: (value: string) => void;
  placeholderMode?: 'classic' | 'short';
};

export const Multiselect = ({
  id,
  label,
  options,
  placeholder,
  disabled,
  handleSelect,
  placeholderMode = 'classic',
}: MultiselectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = (event: MouseEvent<HTMLDivElement>, value: string) => {
    event.preventDefault();
    handleSelect(value);
  };

  const selectedPlaceholder = options
    .filter(option => option.checked)
    .map(option => option.text)
    .join(', ');

  const defaultTriggerWidth = '200px';

  return (
    <StyledWrapper>
      <Flex direction="column">
        {label && (
          <Label
            htmlFor="id"
            css={{
              fontWeight: '$medium',
              color: '$gray750',
              fontSize: '$sm',
              mb: '16px',
            }}
          >
            {label}
          </Label>
        )}
        <StyledTrigger
          id={id}
          css={{ width: defaultTriggerWidth }}
          disabled={disabled}
          onClick={() => setIsOpen(currentIsOpen => !currentIsOpen)}
          isEmpty={!selectedPlaceholder}
        >
          {placeholderMode === 'classic' ? (
            <span>{selectedPlaceholder || placeholder}</span>
          ) : (
            <span
              style={{
                width: defaultTriggerWidth,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedPlaceholder || placeholder}
            </span>
          )}
          <StyledIcon>
            <FaChevronDown />
          </StyledIcon>
        </StyledTrigger>
        <StyledContent isOpen={isOpen}>
          {options.map(option => (
            <StyledItem
              key={option.value}
              onClick={event => onClick(event, option.value)}
            >
              <Checkbox size="md" checked={option.checked} />
              <span>{option.text}</span>
            </StyledItem>
          ))}
        </StyledContent>
      </Flex>
    </StyledWrapper>
  );
};
