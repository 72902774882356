import * as React from 'react';
import { ModuleDropdown } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useModuleItems } from './hooks/useModuleItems';

export function ModulesDropdown() {
  const { t } = useTranslate();

  const { items } = useModuleItems();

  return <ModuleDropdown title={t('menu.quick_acess')} items={items} />;
}
