export const VideoIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 16.25H2.5C2.16848 16.25 1.85054 16.1183 1.61612 15.8839C1.3817 15.6495 1.25 15.3315 1.25 15V5C1.25 4.66848 1.3817 4.35054 1.61612 4.11612C1.85054 3.8817 2.16848 3.75 2.5 3.75H13.125C13.4565 3.75 13.7745 3.8817 14.0089 4.11612C14.2433 4.35054 14.375 4.66848 14.375 5V7.5375L17.7625 5.11875C17.8558 5.05233 17.9655 5.01282 18.0797 5.00452C18.1939 4.99623 18.3082 5.01947 18.4101 5.07171C18.512 5.12395 18.5976 5.20318 18.6576 5.30075C18.7175 5.39833 18.7495 5.51049 18.75 5.625V14.375C18.7495 14.4895 18.7175 14.6017 18.6576 14.6992C18.5976 14.7968 18.512 14.8761 18.4101 14.9283C18.3082 14.9805 18.1939 15.0038 18.0797 14.9955C17.9655 14.9872 17.8558 14.9477 17.7625 14.8812L14.375 12.4625V15C14.375 15.3315 14.2433 15.6495 14.0089 15.8839C13.7745 16.1183 13.4565 16.25 13.125 16.25ZM2.5 5V15H13.125V11.25C13.1255 11.1355 13.1575 11.0233 13.2174 10.9258C13.2774 10.8282 13.363 10.7489 13.4649 10.6967C13.5668 10.6445 13.6811 10.6212 13.7953 10.6295C13.9095 10.6378 14.0192 10.6773 14.1125 10.7438L17.5 13.1625V6.8375L14.1125 9.25625C14.0192 9.32267 13.9095 9.36218 13.7953 9.37048C13.6811 9.37877 13.5668 9.35553 13.4649 9.30329C13.363 9.25105 13.2774 9.17182 13.2174 9.07425C13.1575 8.97667 13.1255 8.86451 13.125 8.75V5H2.5Z"
      fill="#E5B611"
    />
  </svg>
);
