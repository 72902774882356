/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */

import * as React from 'react';

export function initializeAxeCore() {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');

  axe(React, ReactDOM, 1000);
}
