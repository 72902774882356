type MoneyBagProps = {
  size?: number;
};

export const MoneyBagIcon = ({ size = 38 }: MoneyBagProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 38 40"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8274 20.7428C23.5209 19.876 22.9533 19.1257 22.2026 18.5949C21.452 18.0642 20.5553 17.7792 19.636 17.7792V16.668H17.4136V17.7792C16.2347 17.7792 15.1042 18.2475 14.2706 19.081C13.437 19.9146 12.9688 21.0451 12.9688 22.224C12.9688 23.4028 13.437 24.5334 14.2706 25.3669C15.1042 26.2005 16.2347 26.6688 17.4136 26.6688V31.1136C16.4468 31.1136 15.6234 30.4969 15.3167 29.6324C15.2712 29.4909 15.1978 29.3601 15.1008 29.2475C15.0039 29.1349 14.8853 29.0429 14.7522 28.977C14.6191 28.911 14.474 28.8724 14.3257 28.8635C14.1774 28.8546 14.0288 28.8755 13.8887 28.925C13.7486 28.9745 13.6199 29.0515 13.5101 29.1517C13.4003 29.2518 13.3118 29.3729 13.2496 29.5079C13.1875 29.6428 13.153 29.7889 13.1483 29.9374C13.1435 30.0859 13.1687 30.2338 13.2221 30.3724C13.5286 31.2392 14.0963 31.9895 14.8469 32.5203C15.5975 33.051 16.4943 33.336 17.4136 33.336V34.4472H19.636V33.336C20.8148 33.336 21.9454 32.8677 22.7789 32.0342C23.6125 31.2006 24.0808 30.07 24.0808 28.8912C24.0808 27.7124 23.6125 26.5818 22.7789 25.7483C21.9454 24.9147 20.8148 24.4464 19.636 24.4464V20.0016C20.0956 20.0015 20.5439 20.1438 20.9192 20.4091C21.2946 20.6744 21.5784 21.0495 21.7317 21.4828C21.8298 21.7607 22.0344 21.9883 22.3003 22.1154C22.4319 22.1783 22.5747 22.2147 22.7204 22.2225C22.8662 22.2303 23.012 22.2092 23.1496 22.1606C23.2872 22.1121 23.4139 22.0368 23.5224 21.9393C23.631 21.8417 23.7192 21.7237 23.7822 21.5921C23.8451 21.4604 23.8815 21.3176 23.8893 21.1719C23.897 21.0262 23.876 20.8804 23.8274 20.7428ZM17.4136 20.0016C16.8241 20.0016 16.2589 20.2357 15.8421 20.6525C15.4253 21.0693 15.1912 21.6346 15.1912 22.224C15.1912 22.8134 15.4253 23.3787 15.8421 23.7955C16.2589 24.2122 16.8241 24.4464 17.4136 24.4464V20.0016ZM19.636 31.1136C20.2254 31.1136 20.7907 30.8795 21.2075 30.4627C21.6242 30.0459 21.8584 29.4806 21.8584 28.8912C21.8584 28.3018 21.6242 27.7365 21.2075 27.3197C20.7907 26.9029 20.2254 26.6688 19.636 26.6688V31.1136Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4726 2.91247C9.19949 1.57791 13.6732 0 18.5692 0C23.3629 0 27.7188 1.51235 30.4313 2.82912L30.5846 2.90358C31.4025 3.30805 32.0625 3.69031 32.527 4.00033L28.4222 10.0008C37.8852 19.675 45.1947 40 18.5692 40C-8.05639 40 -0.943572 20.0439 8.60167 10.0008L4.52355 4.00033C4.83802 3.79476 5.23694 3.55585 5.71254 3.3025C5.94811 3.17582 6.20147 3.0447 6.4726 2.91247ZM25.7842 9.92083L29.07 5.11709C26.0142 5.33711 22.3784 6.05495 18.8781 7.06837C16.3779 7.79065 13.5999 7.68064 11.0274 7.20616C10.3792 7.08598 9.73539 6.94291 9.09726 6.77723L11.2308 9.9186C15.8034 11.5465 21.2105 11.5465 25.7842 9.92083ZM9.94734 11.8121C15.2978 13.8789 21.7283 13.8789 27.0788 11.8099C29.3121 14.1642 31.1804 16.8396 32.6214 19.7472C34.1238 22.8119 34.9416 25.8755 34.8083 28.5157C34.6794 31.0637 33.6771 33.2405 31.388 34.8585C29.0023 36.5442 24.9897 37.7776 18.5681 37.7776C12.1397 37.7776 8.10829 36.5653 5.70031 34.8985C3.39457 33.3005 2.38226 31.1537 2.23892 28.6402C2.0889 26.0288 2.88897 22.9808 4.38576 19.895C5.81366 16.9525 7.80605 14.1201 9.94734 11.8121ZM8.66945 4.35147C9.55841 4.61594 10.4874 4.84596 11.4297 5.02042C13.8188 5.46045 16.2145 5.5249 18.2591 4.93263C20.6418 4.23851 23.0652 3.69261 25.5153 3.29805C23.4707 2.68245 21.0794 2.22241 18.5681 2.22241C14.74 2.22241 11.1697 3.29027 8.66945 4.35147Z"
        fill="currentColor"
      />
    </svg>
  );
};
