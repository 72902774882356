import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { AppMenu, Flex, Text } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { BackArrowIcon } from '~/shared/components/icons';

import cheersMenuLogo from '~/../public/img/cheers_menu_logo.png';

import { useMenuFooterHeight } from '../../hooks/useMenuFooterHeight';
import { useMenuListScroll } from '../../hooks/useMenuListScroll';

export function DesktopMenu() {
  const {
    isAppMenuOpen,
    subMenuOpen,
    menuHeaderItem,
    menuItems,
    footerItems,
    toggleAppMenu,
    checkIfIsActive,
    toggleSubMenu,
  } = useNav();

  const { elementRef, isScrollable } = useMenuListScroll();
  const { footerRef, footerHeight } = useMenuFooterHeight();

  const hasFooterItems = !!footerItems.length;

  const redirectToPath = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <AppMenu open={isAppMenuOpen}>
      <AppMenu.Trigger asChild>
        <AppMenu.Bar openMenu={toggleAppMenu} openMenuDelay={300}>
          <AppMenu.Header css={{ p: 0 }}>
            <AppMenu.MenuItem
              css={{ p: '$3 $2' }}
              leftIcon={menuHeaderItem.icon}
              isTriggerItem
            />
          </AppMenu.Header>
          <AppMenu.MenuList>
            {menuItems.map(item => (
              <AppMenu.MenuItem
                key={item.key}
                leftIcon={item.icon}
                isActive={checkIfIsActive(item.path, item.routeComponents)}
                isTriggerItem
              />
            ))}
          </AppMenu.MenuList>
          {hasFooterItems && (
            <AppMenu.Footer>
              {footerItems.map(item => (
                <AppMenu.MenuItem
                  key={item.key}
                  leftIcon={item.icon}
                  isActive={checkIfIsActive(item.path, item.routeComponents)}
                  isTriggerItem
                />
              ))}
            </AppMenu.Footer>
          )}
        </AppMenu.Bar>
      </AppMenu.Trigger>
      <AppMenu.Content
        closeMenuDelay={300}
        css={{
          justifyContent: hasFooterItems ? 'space-between' : 'flex-start',
          '> div': {
            '&:nth-child(2)': {
              height: `calc($full - ${footerHeight}px) !important`,
              mb: '$1',
            },
          },
        }}
        closeMenu={() => {
          toggleAppMenu();
          toggleSubMenu(null);
        }}
      >
        <AppMenu.Header css={{ p: 0 }}>
          <AppMenu.MenuItem
            title={menuHeaderItem.title}
            hasSubmenu
            css={{ p: '$3 $2' }}
            leftIcon={menuHeaderItem.icon}
            isSubMenuOpen={subMenuOpen?.key === menuHeaderItem.key}
            onClick={() => toggleSubMenu(menuHeaderItem)}
            renderSubmenu={
              <AppMenu.SubMenu
                title={menuHeaderItem.title || ''}
                alignWithHeader
                hideTrigger
                renderTitle={
                  <Flex align="center" gap="md" css={{ height: '$full' }}>
                    <AppMenu.MenuIconButton
                      onClick={() => toggleSubMenu(null)}
                      icon={<BackArrowIcon />}
                    />
                    <Text css={{ fontWeight: '$medium' }}>
                      {menuHeaderItem.title}
                    </Text>
                  </Flex>
                }
              >
                <AppMenu.MenuList>
                  {menuHeaderItem.routeComponents?.map(subitem => (
                    <AppMenu.MenuItem
                      key={subitem.key}
                      title={subitem.title}
                      route={subitem.path}
                      leftIcon={subitem.icon}
                      isSubMenuItem
                    />
                  ))}
                </AppMenu.MenuList>
              </AppMenu.SubMenu>
            }
          />
        </AppMenu.Header>
        <AppMenu.MenuList
          isMainList
          ref={elementRef}
          bottomElementCss={{
            mb: hasFooterItems
              ? `calc(${footerHeight}px - $appHeaderHeight + $1-5)`
              : '-$16',
            width: isScrollable
              ? 'calc($appMenuOpenWidth - $2)'
              : '$appMenuOpenWidth',
          }}
          bottomElement={
            <Image src={cheersMenuLogo} placeholder="blur" alt="Cheers Hub" />
          }
        >
          {menuItems.map(item => (
            <AppMenu.MenuItem
              key={item.key}
              route={item.path}
              title={item.title}
              leftIcon={item.icon}
              hasSubmenu={!!item.routeComponents?.length}
              isSubMenuOpen={subMenuOpen?.key === item.key}
              onHover={() => toggleSubMenu(item)}
              onHoverDelay={300}
              as={Link}
              isActive={checkIfIsActive(item.path, item.routeComponents)}
              renderSubmenu={
                <AppMenu.SubMenu
                  title={item.title || ''}
                  css={{ borderRadius: '$4' }}
                >
                  <AppMenu.MenuList>
                    {item.routeComponents?.map(subitem => (
                      <AppMenu.MenuItem
                        key={subitem.key}
                        title={subitem.title}
                        route={subitem.path}
                        as={Link}
                        isSubMenuItem
                      />
                    ))}
                  </AppMenu.MenuList>
                </AppMenu.SubMenu>
              }
            />
          ))}
        </AppMenu.MenuList>
        {hasFooterItems && (
          <AppMenu.Footer ref={footerRef}>
            <AppMenu.MenuList css={{ p: 0 }}>
              {footerItems.map(item => (
                <AppMenu.MenuItem
                  key={item.key}
                  {...(item.key === 'equatePlus' ? {} : { route: item.path })}
                  title={item.title}
                  hasSubmenu={!!item.routeComponents?.length}
                  leftIcon={item.icon}
                  isSubMenuOpen={subMenuOpen?.key === item.key}
                  onHover={() => toggleSubMenu(item)}
                  onHoverDelay={300}
                  isActive={checkIfIsActive(item.path, item.routeComponents)}
                  {...(item.key === 'equatePlus' ? {} : { as: Link })}
                  onClick={() => {
                    if (item.key === 'equatePlus') {
                      redirectToPath(item.path);
                    }
                  }}
                  renderSubmenu={
                    <AppMenu.SubMenu
                      title={item.title || ''}
                      css={{ borderRadius: '$4' }}
                    >
                      <AppMenu.MenuList>
                        {item.routeComponents?.map(subitem => (
                          <AppMenu.MenuItem
                            key={subitem.key}
                            title={subitem.title}
                            route={subitem.path}
                            as={Link}
                            isSubMenuItem
                          />
                        ))}
                      </AppMenu.MenuList>
                    </AppMenu.SubMenu>
                  }
                />
              ))}
            </AppMenu.MenuList>
          </AppMenu.Footer>
        )}
      </AppMenu.Content>
    </AppMenu>
  );
}
