import { Box, CSS, Flex, styled } from '@ghq-abi/design-system';

export const StyledWrapper = styled(Flex, {
  pt: '$appHeaderHeight',
  minHeight: '100vh',

  variants: {
    fullscreen: { true: { pt: 0 } },
  },
});

const fullscreenStyles: CSS = {
  p: 0,
};

export const StyledContent = styled(Box, {
  flex: 1,
  maxWidth: '$full',
  pt: '$8',
  pb: '$lg',
  pr: '$lg',
  pl: '$lg',
  display: 'flex',
  flexDirection: 'column',

  '@lg': {
    pl: 'calc($appMenuClosedWidth + $lg)',
  },

  variants: {
    fullscreen: {
      true: fullscreenStyles,
    },
  },

  compoundVariants: [
    {
      fullscreen: true,
      css: fullscreenStyles,
    },
  ],
});
