import '@ghq-abi/design-system/css';

import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { SessionProvider, SessionProviderProps, signIn } from 'next-auth/react';
import NextNProgress from 'nextjs-progressbar';
import { DesignSystemProvider, theme } from '@ghq-abi/design-system';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CredentialProvidersEnum } from 'abi-people-platform-auth-client-lib';

import { ErrorBoundary } from '~/app/components';
import { AbilityProvider } from '~/app/contexts/AbilityContext';
import { AuthProvider } from '~/app/contexts/AuthContext';
import { BudgetAndAllocatorsProvider } from '~/app/contexts/BudgetAndAllocatorsContext';
import { FullscreenProvider } from '~/app/contexts/FullscreenContext';
import { NavProvider } from '~/app/contexts/NavContext';
import { SessionInfoProvider } from '~/app/contexts/SessionInfoContext';
import { ToastProvider } from '~/app/contexts/ToastContext';
import { globalStyles } from '~/app/styles/global';
import { AppTemplate } from '~/app/templates/App';
import { createUserAbility } from '~/shared/auth/permissions';
import { NpsMetrics } from '~/shared/components/Metrics';
import {
  GA,
  initializeAxeCore,
  queryClientConfig,
  useDatadog,
} from '~/shared/lib';
import { isClient, isLocal } from '~/shared/utils/environments';

if (isClient() && isLocal()) {
  initializeAxeCore();
}

globalStyles();

type CustomAppProps = {
  Component: AppProps['Component'] & { unauth?: boolean };
  pageProps: AppProps['pageProps'] & {
    session: SessionProviderProps['session'];
    now?: number;
    dehydratedState?: unknown;
  };
};

export default function App({
  Component,
  pageProps: { session, language, messages, now, ...pageProps },
}: CustomAppProps) {
  // https://tanstack.com/query/v4/docs/react/guides/ssr#using-hydration
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));
  const router = useRouter();

  const locale = session?.user?.proxiedAs?.language || session?.user?.language;

  useDatadog(locale, session);

  const handleOldCookiesReq = async () => {
    const res = await fetch('/api/old-cookies-handler');

    if (res.status === 200) {
      return;
    }

    signIn(CredentialProvidersEnum.AZURE_AD);
  };

  useEffect(() => {
    handleOldCookiesReq();
  }, []);

  useEffect(() => {
    if (
      !router.pathname.includes('/unauthorized') &&
      (session?.user?.managementLevelId ===
        'GBL_Management_Level_Blue_Collar' ||
        session?.user?.proxiedAs?.managementLevelId ===
          'GBL_Management_Level_Blue_Collar')
    ) {
      router.push('/unauthorized');
    }
  }, [router, session]);

  return (
    // TODO: move providers to app folder
    <>
      <ErrorBoundary>
        <SessionProvider session={session}>
          <SessionInfoProvider sessionInfo={session}>
            <QueryClientProvider client={queryClient}>
              <DesignSystemProvider>
                {Component.unauth ? (
                  <Component {...pageProps} />
                ) : (
                  <ToastProvider>
                    <AbilityProvider ability={createUserAbility(session?.user)}>
                      <FullscreenProvider
                        startState={pageProps.startFullscreenState}
                      >
                        <AuthProvider>
                          <NavProvider>
                            <BudgetAndAllocatorsProvider>
                              <AppTemplate>
                                <Hydrate state={pageProps.dehydratedState}>
                                  <Component {...pageProps} />
                                </Hydrate>
                              </AppTemplate>
                            </BudgetAndAllocatorsProvider>
                            <NextNProgress
                              color={theme.colors.black.toString()}
                              startPosition={0.3}
                              stopDelayMs={200}
                              height={3}
                            />
                          </NavProvider>
                        </AuthProvider>
                      </FullscreenProvider>
                    </AbilityProvider>
                  </ToastProvider>
                )}
              </DesignSystemProvider>

              <ReactQueryDevtools />
            </QueryClientProvider>
          </SessionInfoProvider>

          <NpsMetrics.Script
            employeeGlobalId={session?.user?.globalId}
            locale={locale}
          />
        </SessionProvider>
      </ErrorBoundary>

      <GA.Script session={session} />
    </>
  );
}
