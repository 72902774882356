import { theme } from '@ghq-abi/design-system';

type FilterProps = {
  color?: string;
};

export function Filter({ color = theme.colors.blue350.value }: FilterProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={color}
        d="M10.5 18h-3A1.5 1.5 0 016 16.5v-5.693L.442 5.25A1.5 1.5 0 010 4.192V1.5A1.5 1.5 0 011.5 0h15A1.5 1.5 0 0118 1.5v2.692a1.5 1.5 0 01-.442 1.058L12 10.807V16.5a1.5 1.5 0 01-1.5 1.5zm-9-16.5v2.692l6 6V16.5h3v-6.307l6-6V1.5h-15z"
      />
    </svg>
  );
}
