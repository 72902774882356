import * as React from 'react';
import Image from 'next/image';
import { Box, Flex, Text } from '@ghq-abi/design-system';

import { useResponsive } from '~/shared/utils/media';

import { MobileMenu } from '../Nav/components/MobileMenu';

import { StyledActionWrapper } from './styles';

export function HeaderActions() {
  const { isMobile } = useResponsive();

  return (
    <StyledActionWrapper>
      {isMobile && <MobileMenu />}
      <Flex align="center" gap="sm">
        <Text
          css={{
            fontSize: '$2xl',
            fontWeight: '$bold',
            background: 'linear-gradient(90deg, #D3A238 3.33%, #F5E003 40.91%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }}
        >
          REWARDS
        </Text>

        <Box
          css={{
            display: 'none',
            '@lg': { display: 'block' },
            fontSize: 0,
          }}
        >
          <Image src="/img/abi_logo.png" alt="AbInBev" width={97} height={18} />
        </Box>
      </Flex>
    </StyledActionWrapper>
  );
}
