import Script from 'next/script';

import { canDisplayMetricsNpsSurvey } from '~/shared/utils/environments';

type NpsScriptProps = {
  employeeGlobalId: string;
  locale: string;
};

const SYSTEM = 'REWARDS';

const NpsMetricsScript = ({ employeeGlobalId, locale }: NpsScriptProps) => {
  if (!canDisplayMetricsNpsSurvey()) {
    return null;
  }

  return (
    <>
      <Script
        type="text/javascript"
        src={process.env.NEXT_PUBLIC_METRICS_URL}
        strategy="afterInteractive"
      />
      <Script id="plugin" strategy="afterInteractive">
        {`
            var metricsPlugin;
            setTimeout(() => {
                metricsPlugin.plugin("${employeeGlobalId}", "${SYSTEM}", "${locale}")
            }, 2000)
        `}
      </Script>
    </>
  );
};

export const NpsMetrics = {
  Script: NpsMetricsScript,
};
