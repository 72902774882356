import * as React from 'react';
import { Session } from 'next-auth';
import { FormatIcu } from '@tolgee/format-icu';
import {
  BackendFetch,
  DevTools,
  Tolgee,
  TolgeeProvider,
  useTolgeeSSR,
} from '@tolgee/react';
import { AuthenticatedUser } from 'abi-people-platform-auth-client-lib';

import { Roles } from '~/shared/auth/types/next-auth';
import { DEFAULT_LANGUAGE } from '~/shared/constants/i18n';

type UserSessionCustomData = {
  isPartner: boolean;
  persona: string;
  managedZones: string[];
  language: string;
};

export type CommonUserData = {
  globalId: string;
  name: string;
  zone: string;
  band: string;
  employeeId: string;
  roles: Roles;
  token: string;
  refreshToken: string;
} & UserSessionCustomData;

export type RewardsSession = {
  country: string;
  email: string;
  proxiedAs?: CommonUserData | null;
} & AuthenticatedUser &
  CommonUserData;

export type RewardsCustomSessionData = {
  sessionInfo: RewardsSession;
  updateCurrentLanguage?: (newLanguage: string) => void;
};

type AuthProviderProps = {
  children: React.ReactNode;
  sessionInfo: Session | undefined;
};

const SessionInfoContext = React.createContext<RewardsCustomSessionData>(
  {} as RewardsCustomSessionData,
);

const tolgee = Tolgee()
  .use(BackendFetch())
  .use(DevTools())
  .use(FormatIcu())
  .init({
    defaultLanguage:
      (typeof window !== 'undefined' &&
        localStorage.getItem('currentLanguage')) ||
      DEFAULT_LANGUAGE,
    apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
  });

export function SessionInfoProvider(props: AuthProviderProps) {
  const { children } = props;

  const [providerValue, setProviderValue] =
    React.useState<RewardsCustomSessionData>(() => {
      const { sessionInfo } = props;
      if (!sessionInfo) {
        return {} as RewardsCustomSessionData;
      }

      const rewardsCustomSession: RewardsCustomSessionData = {
        sessionInfo: {
          globalId: sessionInfo.user.globalId,
          employeeId: sessionInfo.user.employeeId,
          name: sessionInfo.user.name,
          zone: sessionInfo.user.zone,
          band: sessionInfo.user.band,
          country: sessionInfo.user.country || '',
          email: sessionInfo.user.email,
          language:
            (typeof window !== 'undefined' &&
              localStorage.getItem('currentLanguage')) ||
            sessionInfo.user.language,
          refreshToken: sessionInfo.user.refreshToken,
          token: sessionInfo.user.token,
          isPartner: true,
          persona: sessionInfo.user.persona,
          roles: sessionInfo.user.roles,
          managedZones: sessionInfo.user.managedZones,
          proxiedAs: null,
        },
      };

      if (sessionInfo.user.proxiedAs) {
        rewardsCustomSession.sessionInfo.proxiedAs = {
          managedZones: sessionInfo.user.proxiedAs.roles,
          persona: sessionInfo.user.proxiedAs.persona,
          isPartner: false,
          language:
            (typeof window !== 'undefined' &&
              localStorage.getItem('currentLanguage')) ||
            sessionInfo.user.proxiedAs.language,
          band: sessionInfo.user.proxiedAs.band,
          employeeId: sessionInfo.user.proxiedAs.employeeId,
          globalId: sessionInfo.user.proxiedAs.globalId,
          name: sessionInfo.user.proxiedAs.name,
          roles: sessionInfo.user.proxiedAs.roles,
          token: sessionInfo.user.proxiedAs.token,
          refreshToken: sessionInfo.user.proxiedAs.refreshToken,
          zone: sessionInfo.user.proxiedAs.zone,
        };
      }

      return rewardsCustomSession;
    });

  const locale =
    (typeof window !== 'undefined' &&
      localStorage.getItem('currentLanguage')) ||
    providerValue.sessionInfo?.proxiedAs?.language ||
    providerValue.sessionInfo?.language;

  const ssrTolgee = useTolgeeSSR(tolgee, locale);

  const updateCurrentLanguage = React.useCallback((newLanguage: string) => {
    setProviderValue(prevState => {
      const updatedSessionInfo = { ...prevState.sessionInfo };

      if (updatedSessionInfo.proxiedAs) {
        updatedSessionInfo.proxiedAs.language = newLanguage;
      } else {
        updatedSessionInfo.language = newLanguage;
      }

      if (typeof window !== 'undefined') {
        localStorage.setItem('currentLanguage', newLanguage);
      }
      return { ...prevState, sessionInfo: updatedSessionInfo };
    });
  }, []);

  const memoizedValue = React.useMemo(() => {
    return { sessionInfo: providerValue.sessionInfo, updateCurrentLanguage };
  }, [providerValue, updateCurrentLanguage]);
  return (
    <SessionInfoContext.Provider value={memoizedValue}>
      <TolgeeProvider tolgee={ssrTolgee}>{children}</TolgeeProvider>
    </SessionInfoContext.Provider>
  );
}

export function useSessionInfo() {
  const context = React.useContext(SessionInfoContext);

  if (!context) {
    throw new Error(
      'useSessionInfo must be used within an SessionInfoProvider',
    );
  }

  return context;
}
