import React from 'react';
import { LanguageDropdown } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { LanguagesEnum } from 'abi-opr-common-types';

import { normalizeLanguage } from '~/shared/utils/string';

import { useLanguageDropdown } from './hooks';

type LanguageDropdownProps = {
  currentLanguage?: string;
};

export const LanguageDropdownHeader = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const { t } = useTranslate();
  const { updateLanguage, handleLanguageChange } = useLanguageDropdown();

  const languageOptions = Object.keys(LanguagesEnum).map(lang => ({
    label: t(`languages.${lang}`),
    onSelect: () => handleLanguageChange(lang),
  }));

  const displayLanguage = normalizeLanguage(currentLanguage);

  const userLanguageLabel = t(`languages.${displayLanguage}`);

  return <LanguageDropdown value={userLanguageLabel} items={languageOptions} />;
};
