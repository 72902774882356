import { useResponsiveBreakpoint } from '@ghq-abi/design-system';

export function useResponsive() {
  const breakpoints = useResponsiveBreakpoint();

  const isMobile = ['md', 'sm', 'xs', 'initial'].includes(breakpoints ?? '');
  const isDesktop = ['lg', 'xl', '2xl'].includes(breakpoints ?? '');

  return { isMobile, isDesktop };
}
