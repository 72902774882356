export const WalletIcon = () => {
  return (
    <svg
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.7778 35.6168H5.22222C2.89035 35.6168 1 33.7265 1 31.3946V12.3946C1 10.0627 2.89035 8.17236 5.22222 8.17236H34.7778C37.1097 8.17236 39 10.0627 39 12.3946V31.3946C39 33.7265 37.1097 35.6168 34.7778 35.6168Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M29.5009 22.9505C28.918 22.9505 28.4453 22.4778 28.4453 21.8949C28.4453 21.312 28.918 20.8394 29.5009 20.8394C30.0837 20.8394 30.5564 21.312 30.5564 21.8949C30.5564 22.4778 30.0837 22.9505 29.5009 22.9505Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6667 8.17328V5.22453C32.6667 2.4504 30.0371 0.430086 27.3566 1.14487L4.1343 7.33747C2.28603 7.83035 1 9.50425 1 11.4171V12.3955"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
