import { Box, styled } from '@ghq-abi/design-system';

export const StyledBox = styled(Box, {
  backgroundColor: '$white',
  border: 'solid 1px #EEEFF2',
  borderRadius: '$2',
  p: '$md',
  width: '100%',
  bottom: 0,
  bg: '$white',
});
