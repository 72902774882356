import { Heading, Text } from '@ghq-abi/design-system';

import { StyledBox } from './styles';

type FooterProps = {
  title: string;
  description: string;
};

export const Footer = ({ title, description }: FooterProps) => {
  return (
    <StyledBox>
      <Heading
        css={{
          fontWeight: '$medium',
          fontSize: '$sm',
          color: '#191F2E',
          mb: '$md',
        }}
      >
        {title}
      </Heading>
      <Text
        css={{
          fontSize: '$sm',
          whiteSpace: 'pre-line',
          fontWeight: '$normal',
          lineHeight: '$5',
        }}
      >
        {description}
      </Text>
    </StyledBox>
  );
};
