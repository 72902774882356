import { styled } from '@ghq-abi/design-system';

export const StyledHeader = styled('header', {
  height: '$appHeaderHeight',
  width: '100vw',
  backgroundColor: '$white',
  borderBottom: '1px solid $gray410',
  px: '$4',
  zIndex: '$sticky',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$md',

  position: 'fixed',
  top: 0,

  '@lg': {
    px: '$6',
  },

  variants: {
    isMenuOpen: {
      true: {
        '@lg': {
          left: '$appMenuOpenWidth',
          width: 'calc(100vw - $appMenuOpenWidth)',
        },
      },
      false: {
        '@lg': {
          left: '$appMenuClosedWidth',
          width: 'calc(100vw - $appMenuClosedWidth)',
        },
      },
    },
  },
});
