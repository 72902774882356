import { FC } from 'react';

type EyeIconProps = {
  size?: number;
  color?: string;
};

export const EyeIcon: FC<EyeIconProps> = ({
  size = 15,
  color = '##325A6D',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -1 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8033_2544)">
        <path
          d="M7.60002 9.33301C8.32918 9.33301 8.94898 9.0778 9.45939 8.56738C9.96981 8.05697 10.225 7.43717 10.225 6.70801C10.225 5.97884 9.96981 5.35905 9.45939 4.84863C8.94898 4.33822 8.32918 4.08301 7.60002 4.08301C6.87085 4.08301 6.25106 4.33822 5.74064 4.84863C5.23023 5.35905 4.97502 5.97884 4.97502 6.70801C4.97502 7.43717 5.23023 8.05697 5.74064 8.56738C6.25106 9.0778 6.87085 9.33301 7.60002 9.33301ZM7.60002 8.28301C7.16252 8.28301 6.79064 8.12988 6.48439 7.82363C6.17814 7.51738 6.02502 7.14551 6.02502 6.70801C6.02502 6.27051 6.17814 5.89863 6.48439 5.59238C6.79064 5.28613 7.16252 5.13301 7.60002 5.13301C8.03752 5.13301 8.40939 5.28613 8.71564 5.59238C9.02189 5.89863 9.17502 6.27051 9.17502 6.70801C9.17502 7.14551 9.02189 7.51738 8.71564 7.82363C8.40939 8.12988 8.03752 8.28301 7.60002 8.28301ZM7.60002 11.083C6.18057 11.083 4.88752 10.6868 3.72085 9.89447C2.55418 9.10211 1.70835 8.03995 1.18335 6.70801C1.70835 5.37606 2.55418 4.31391 3.72085 3.52155C4.88752 2.72919 6.18057 2.33301 7.60002 2.33301C9.01946 2.33301 10.3125 2.72919 11.4792 3.52155C12.6459 4.31391 13.4917 5.37606 14.0167 6.70801C13.4917 8.03995 12.6459 9.10211 11.4792 9.89447C10.3125 10.6868 9.01946 11.083 7.60002 11.083ZM7.60002 9.91634C8.69863 9.91634 9.70731 9.62711 10.6261 9.04863C11.5448 8.47016 12.2472 7.68995 12.7334 6.70801C12.2472 5.72606 11.5448 4.94586 10.6261 4.36738C9.70731 3.78891 8.69863 3.49967 7.60002 3.49967C6.50141 3.49967 5.49272 3.78891 4.57397 4.36738C3.65522 4.94586 2.95279 5.72606 2.46668 6.70801C2.95279 7.68995 3.65522 8.47016 4.57397 9.04863C5.49272 9.62711 6.50141 9.91634 7.60002 9.91634Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8033_2544">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.599976)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
