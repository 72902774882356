import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { signIn, signOut } from 'next-auth/react';
import { useTranslate } from '@tolgee/react';
import {
  AuthenticatedUser,
  CredentialProvidersEnum,
} from 'abi-people-platform-auth-client-lib';
import nprogress from 'nprogress';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useAuth } from '~/app/contexts/AuthContext';
import { useSessionInfo } from '~/app/contexts/SessionInfoContext';
import { Icon } from '~/shared/components';
import { SignOut } from '~/shared/components/icons/SignOut';

import { HeaderProps } from '../types';

export const useUserDropdown = ({ user, isProxying }: HeaderProps) => {
  const { t } = useTranslate();
  const router = useRouter();
  const ability = useAbility();
  const { sessionInfo } = useSessionInfo();
  const { notifyChangedProxy } = useAuth();

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-cookies');
    } finally {
      signOut({ redirect: false });
    }
  }

  const goToProxy = useCallback(async () => {
    router.push('/proxy');
  }, [router]);

  const handleStopProxy = useCallback(async () => {
    if (!sessionInfo) {
      return;
    }

    nprogress.start();

    const userObject = JSON.stringify({
      ...sessionInfo,
      proxiedAs: null,
    } as AuthenticatedUser);
    await signIn(CredentialProvidersEnum.REGISTER_PROXY, { user: userObject });

    notifyChangedProxy();
  }, [notifyChangedProxy, sessionInfo]);

  const items = useMemo(() => {
    const dropdownItems = [
      {
        label: t('common.sign_out'),
        onSelect: handleSignOut,
        icon: <SignOut />,
      },
    ];

    if (ability.can('proxy', 'User')) {
      dropdownItems.unshift({
        label: t(isProxying ? 'common.stop_proxy' : 'common.proxy_as_user'),
        onSelect: isProxying ? handleStopProxy : goToProxy,
        icon: isProxying ? (
          <Icon.StopProxy size={18} />
        ) : (
          <Icon.Proxy size={18} />
        ),
      });
    }

    return dropdownItems;
  }, [ability, goToProxy, handleStopProxy, isProxying, t]);

  const profileInfo = useMemo(() => {
    return {
      name: user.name ?? '',
      urlImage:
        process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL && user.globalId
          ? `${process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL}/thumb/${user.globalId}`
          : undefined,
      zone: user.zone ?? '',
    };
  }, [user.globalId, user.name, user.zone]);

  return {
    items,
    profileInfo,
  };
};
