import { CSS, Flex, Text } from '@ghq-abi/design-system';

type LegendProps = {
  items: {
    name: string;
    color: string;
  }[];
  css?: CSS;
};

export const Legend = ({ items, css = {} }: LegendProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      gap="sm"
      css={{
        marginTop: '$sm',
        py: '$1-5',
        ...css,
      }}
    >
      <Text
        css={{
          fontSize: '$sm',
          fontWeight: '$medium',
          // TODO: UX - Replace colors with var after DS update
          color: '#191F2E',
          mr: '$sm',
        }}
      >
        Legend:
      </Text>

      {items.map(item => (
        <Text
          key={item.color}
          css={{
            fontSize: '$sm',
            fontWeight: '$medium',
            // TODO: UX - Replace colors with var after DS update
            color: '#8C93A3',
            backgroundColor: '#F5F6F7',
            padding: '$1-5 $sm',
            height: '$8',
            borderRadius: '$2',
          }}
        >
          <svg width="9" height="9" style={{ marginRight: 6 }}>
            <rect width="9" height="9" rx="2" ry="2" fill={item.color} />
          </svg>
          {item.name}
        </Text>
      ))}
    </Flex>
  );
};
