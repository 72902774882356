export const HomeIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3878 7.86624L9.88783 0.788111L9.87939 0.780611C9.63841 0.559914 9.3235 0.4375 8.99673 0.4375C8.66996 0.4375 8.35505 0.559914 8.11408 0.780611L8.10564 0.788111L0.605638 7.86624C0.472982 7.98974 0.367344 8.13939 0.295382 8.30574C0.223419 8.47209 0.186692 8.65155 0.187513 8.8328V17.5C0.187513 17.8481 0.325794 18.1819 0.571936 18.4281C0.818077 18.6742 1.15192 18.8125 1.50001 18.8125H6.00001C6.34811 18.8125 6.68195 18.6742 6.92809 18.4281C7.17423 18.1819 7.31251 17.8481 7.31251 17.5V13C7.31251 12.9503 7.33227 12.9026 7.36743 12.8674C7.40259 12.8322 7.45029 12.8125 7.50001 12.8125H10.5C10.5497 12.8125 10.5974 12.8322 10.6326 12.8674C10.6678 12.9026 10.6875 12.9503 10.6875 13V17.5C10.6875 17.8481 10.8258 18.1819 11.0719 18.4281C11.3181 18.6742 11.6519 18.8125 12 18.8125H16.5C16.8481 18.8125 17.1819 18.6742 17.4281 18.4281C17.6742 18.1819 17.8125 17.8481 17.8125 17.5V8.8328C17.8125 8.65106 17.7747 8.47131 17.7016 8.30493C17.6285 8.13855 17.5217 7.98917 17.3878 7.86624ZM16.6875 17.5C16.6875 17.5497 16.6678 17.5974 16.6326 17.6326C16.5974 17.6677 16.5497 17.6875 16.5 17.6875H12C11.9503 17.6875 11.9026 17.6677 11.8674 17.6326C11.8323 17.5974 11.8125 17.5497 11.8125 17.5V13C11.8125 12.6519 11.6742 12.318 11.4281 12.0719C11.1819 11.8258 10.8481 11.6875 10.5 11.6875H7.50001C7.15192 11.6875 6.81808 11.8258 6.57194 12.0719C6.32579 12.318 6.18751 12.6519 6.18751 13V17.5C6.18751 17.5497 6.16776 17.5974 6.1326 17.6326C6.09743 17.6677 6.04974 17.6875 6.00001 17.6875H1.50001C1.45029 17.6875 1.40259 17.6677 1.36743 17.6326C1.33227 17.5974 1.31251 17.5497 1.31251 17.5V8.8328C1.31246 8.8067 1.31785 8.78088 1.32834 8.75699C1.33883 8.7331 1.3542 8.71166 1.37345 8.69405L1.38189 8.68655L8.87626 1.6103C8.91042 1.58049 8.95421 1.56407 8.99955 1.56407C9.04488 1.56407 9.08867 1.58049 9.12283 1.6103L16.6181 8.68749L16.6256 8.69499C16.6451 8.71252 16.6606 8.73392 16.6713 8.75781C16.6819 8.78171 16.6875 8.80757 16.6875 8.83374V17.5Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
  </svg>
);
