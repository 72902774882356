import { useState } from 'react';
import { Box, styled } from '@ghq-abi/design-system';

import { SliderBubble } from './SliderBubble';

const StyledLabels = styled('ul', {
  listStyle: 'none',
  padding: 0,
  fontSize: '$sm',
  color: '#3F465A',

  '& li:first-child': {
    position: 'relative',
    float: 'left',
    textAlign: 'center',
  },

  '& li:last-child': {
    position: 'relative',
    float: 'right',
    textAlign: 'center',
  },
});

const StyledSlider = styled('input', {
  '-webkit-appearance': 'none',
  appearance: 'none',
  width: '100%',
  height: 4,
  background: '$gray450',
  outline: 'none',
  borderRadius: 5,
  mb: '$sm',

  '&::-webkit-slider-thumb': {
    '-webkit-appearance': 'none',
    height: 20,
    width: 20,
    background: '$blue350',
    borderRadius: 20,
    cursor: 'pointer',
  },

  '&:hover': {
    span: {
      opacity: 1,
    },
  },
});

type PercentageSliderProps = {
  value?: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  bubbleVisible?: boolean;
};

export const PercentageSlider = ({
  value,
  onChange,
  min,
  max,
  bubbleVisible,
}: PercentageSliderProps) => {
  const [hover, setHover] = useState(false);

  return (
    <Box css={{ width: '$full', position: 'relative' }}>
      <StyledSlider
        type="range"
        min={min}
        max={max}
        value={value ?? 15}
        onChange={ev => onChange(+ev.target.value)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <StyledLabels>
        <li>{min}%</li>
        <li>{max}%</li>
      </StyledLabels>
      <SliderBubble
        value={value ?? 15}
        min={min}
        max={max}
        visible={bubbleVisible || hover}
        css={{ top: 'calc($16 + $2)' }}
      />
    </Box>
  );
};
