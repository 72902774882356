import * as React from 'react';
import { useRouter } from 'next/router';

export type FullscreenContextData = {
  activated: boolean;
  deactivated: boolean;
  activate: () => void;
  deactivate: () => void;
  toggle: () => void;
};

const FullscreenContext = React.createContext({} as FullscreenContextData);

type FullscreenProviderProps = {
  children: React.ReactNode;
  startState?: boolean;
};

export function FullscreenProvider({
  children,
  startState = false,
}: FullscreenProviderProps) {
  const router = useRouter();

  const [activated, setActivated] = React.useState(startState);

  React.useEffect(() => {
    function handleRouteChange() {
      setActivated(false);
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  function activate() {
    setActivated(true);
  }

  function deactivate() {
    setActivated(false);
  }

  function toggle() {
    setActivated(state => !state);
  }

  const providerValue = React.useMemo(
    () => ({
      activated,
      deactivated: !activated,
      activate,
      deactivate,
      toggle,
    }),
    [activated],
  );

  return (
    <FullscreenContext.Provider value={providerValue}>
      {children}
    </FullscreenContext.Provider>
  );
}

export function useFullscreen() {
  const context = React.useContext(FullscreenContext);

  if (!context) {
    throw new Error('useFullscreen must be used within a FullscreenProvider');
  }

  return context;
}
