import { ReactNode } from 'react';
import { Box, CSS, Heading, styled, Text } from '@ghq-abi/design-system';

const StyledGrid = styled(Box, {
  display: 'grid',
  gridTemplateColumns: '114px repeat(3, 1fr)',
  rowGap: '16px',
});

type GraphGridProps = {
  columns?: number;
  css?: CSS;
  children: ReactNode;
};

export const GraphGrid = ({
  columns = 4,
  css = {},
  children,
}: GraphGridProps) => (
  <StyledGrid
    css={{ gridTemplateColumns: `114px repeat(${columns - 1}, 1fr)`, ...css }}
  >
    {children}
  </StyledGrid>
);

export const TableGrid = ({
  columns = 4,
  css = {},
  children,
}: GraphGridProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      px: 10,
      ...css,
    }}
  >
    {children}
  </Box>
);

export const CustomLegendText = ({ children }: { children: ReactNode }) => (
  <Text
    css={{
      fontSize: '$md',
      fontWeight: '$medium',
      color: '#191F2E',
      textAlign: 'center',
    }}
  >
    {children}
  </Text>
);

export const ValueText = styled(Text, {
  textAlign: 'center',
  fontSize: '$sm',
  fontWeight: '$normal',
  color: '#3F465A',
});

export const HeadingText = styled(Heading, {
  fontSize: '$sm',
  fontWeight: '$medium',
  color: '$dark400',
  gridColumnStart: '1',
});
