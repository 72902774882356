import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button, Dropdown } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

type OptionsItem = {
  label: string;
  value: string;
  onSelect: () => void;
};

type MassActionsDropdownProps = {
  disabledButton: boolean;
  optionsList: OptionsItem[];
};

export function MassActionsDropdown({
  disabledButton,
  optionsList,
}: MassActionsDropdownProps) {
  const { t } = useTranslate(['process_configuration']);

  return (
    <Dropdown>
      <Dropdown.Trigger asChild disabled={disabledButton}>
        <Button
          css={{ minWidth: 'fit-content' }}
          disabled={disabledButton}
          variant="secondary"
          leftIcon={<BsThreeDotsVertical style={{ flexShrink: 0 }} />}
        >
          {t('process_configuration.mass_actions')}
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content css={{ zIndex: '$dropdown' }}>
        {optionsList.map(option => (
          <Dropdown.Item key={option.label} onSelect={option.onSelect}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
