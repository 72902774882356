import * as React from 'react';

type UserBadgeProps = {
  size?: number;
  color?: string;
};

export function UserBadge({ size = 24, color = '#ffffff' }: UserBadgeProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="none"
      color={color}
    >
      <path
        d="M3 1.5H2C0.895431 1.5 0 2.39543 0 3.5V14C0 15.1046 0.895431 16 2 16H12C13.1046 16 14 15.1046 14 14V3.5C14 2.39543 13.1046 1.5 12 1.5H11V2.5H12C12.5523 2.5 13 2.94772 13 3.5V14C13 14.5523 12.5523 15 12 15H2C1.44772 15 1 14.5523 1 14V3.5C1 2.94772 1.44772 2.5 2 2.5H3V1.5Z"
        fill="#191F2E"
      />
      <path
        d="M8.5 1C8.77614 1 9 1.22386 9 1.5V2.5C9 2.77614 8.77614 3 8.5 3H5.5C5.22386 3 5 2.77614 5 2.5V1.5C5 1.22386 5.22386 1 5.5 1H8.5ZM5.5 0C4.67157 0 4 0.671573 4 1.5V2.5C4 3.32843 4.67157 4 5.5 4H8.5C9.32843 4 10 3.32843 10 2.5V1.5C10 0.671573 9.32843 0 8.5 0H5.5Z"
        fill="#191F2E"
      />

      <foreignObject width="100%" height="100%">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="18"
            viewBox="0 0 6 4"
            fill="none"
          >
            <path
              d="M5.85355 0.146447C6.04882 0.341709 6.04882 0.658292 5.85355 0.853554L2.85355 3.85355C2.75979 3.94732 2.63261 4 2.5 4C2.36739 4 2.24021 3.94732 2.14645 3.85355L0.646447 2.35355C0.451184 2.15829 0.451184 1.84171 0.646447 1.64645C0.841709 1.45118 1.15829 1.45118 1.35355 1.64645L2.5 2.79289L5.14645 0.146447C5.34171 -0.0488155 5.65829 -0.0488155 5.85355 0.146447Z"
              fill="#191F2E"
            />
          </svg>
        </div>
      </foreignObject>
    </svg>
  );
}
