import { Dialog, styled } from '@ghq-abi/design-system';

export const ModalContentWrapper = styled(Dialog.Content, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#FBFAFC !important',
  '> button': {
    backgroundColor: 'transparent',
  },
  overflowY: 'auto',
});

export const ModalTitle = styled(Dialog.Title, {
  fontSize: '24px',
  fontWeight: '$medium',
  lineHeight: '38.4px',
  color: '#191F2E',
  textAlign: 'center',
  mb: '32px',
});
